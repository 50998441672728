@import "./variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


body {
  margin: 0;
  font-family: 'Ubuntu', 'Segoe UI', 'Roboto', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html.theme--dark {
  background-color: $bg-light;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $bg;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: $primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-light;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  font-family: 'Ubuntu', 'Segoe UI', 'Roboto', 'Oxygen',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

input, button {
  border: none;
  outline: none;
}

p {
  margin: 0;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
