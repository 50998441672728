@import "../../sass/variables.scss";

.fixD .MuiPaper-root {
    background-color: #424242 !important;
}

.fixD .MuiTypography-h6 {
    color: $primary-light !important;
}

.auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100vh - #{$navbar-height});
    margin: 0 auto;
    font-size: 1em;
    transition: font-size 0.3s;

    @media (max-width: $medium) {
        font-size: 0.8em;
    }

    @media (max-height: $medium) {
        font-size: 0.8em;
    }

    .mirror {
        color: $primary-light;
        font-size: 1.6em;
        position: absolute;
        text-decoration: none;
        bottom: 10%;

        &:nth-of-type(1) {
            bottom: 15%;
        }
    }

    .header {
        width: 100%;
        height: 40%;
        background-color: $primary;
        display: flex;
        align-items: center;
        margin-bottom: -130px;
        transition: height 0.3s;

        p {
            color: #132922;
            font-size: 2.5em;
            margin-left: 15%;
            margin-bottom: 30px;
        }

        @media (max-width: $medium) {
            height: 45%;
            p {
                margin-bottom: 40px;
            }
        }
        @media (max-height: 815px) {
            height: 45%;
            p {
                margin-bottom: 40px;
            }
        }
    }
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 20px;
        border-radius: 10px;
        background: $bg;
        z-index: 1;
        width: 450px;
        transition: box-shadow 0.3s;
        box-shadow: 1px 1px 15px #494949;
        
        > * {
            margin: 12px;
        }
        
        .input-wrapper {
            position: relative;
            width: 100%;

            i {
                position: absolute;
                left: 3%;
                top: 11px;
                color: $primary;
                font-size: 28px;

                &:nth-of-type(2) {
                    cursor: pointer;
                    right: 15px;
                    left: unset;
                }
            }
            input:not([type=tel]), .phone-input {
                height: 50px;
                color: $primary !important;
                width: 100%;
                background: $bg !important;
                border: 1px solid #474747;
                border-radius: 3px;
                font-size: 22px;
                padding: 5px 5px 5px 50px;
                transition: border 0.2s;

                &:-webkit-autofill,
                &:-webkit-autofill:hover, 
                &:-webkit-autofill:focus, 
                &:-webkit-autofill:active
                {
                    box-shadow: 0 0 0 40px $bg inset !important;
                    -webkit-text-fill-color: $primary;
                }
                &:-webkit-autofill::first-line {
                    font-size: 22px;
                    font-family: 'Ubuntu';
                }



                &[type=password]{
                    padding-right: 50px;
                }

                &:focus {
                    border: 1px solid #5f5f5f;
                    box-shadow: 1px 1px 15px #494949;
                }

                &::placeholder {
                    color: $primary-light;
                    font-size: 22px;
                }

                &.phone-input {
                    padding: 5px 5px 5px 10px;
                    input {
                        font-size: 22px;
                        height: 100%;
                        color: $primary !important;
                        width: 100%;
                        background: $bg !important;
                        &:-webkit-autofill,
                        &:-webkit-autofill:hover, 
                        &:-webkit-autofill:focus, 
                        &:-webkit-autofill:active
                        {
                            box-shadow: 0 0 0 40px $bg inset !important;
                            -webkit-text-fill-color: $primary;
                        }
                        &:-webkit-autofill::first-line {
                            font-size: 22px;
                            font-family: 'Ubuntu';
                        }
                        &::placeholder {
                            color: $primary-light;
                            font-size: 22px;
                        }
                    }
                }
            }
        }

        input[type=submit] {
            cursor: pointer;
            position: relative;
            width: 100%;
            background: $primary;
            color: $secondary;
            padding: 12px 0;
            font-size: 1.4em;
            border-radius: 50px;
            top: 0px;
            transition: box-shadow 0.2s, top 0.2s;

            &:hover {
                box-shadow: 0 0 6px $primary-light;
            }
            &:active {
                box-shadow: 0 0 15px $primary-light;
                top: 1px;
            }
        }
    }
}