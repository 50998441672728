@import "../../sass/variables.scss";

.add-ticket{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    margin: 20px 40px 70px;
    font-size: 1em;

    @media (max-width: $large) {
        font-size: 0.8em;
    }

    .controllers {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;

        &.top {
            margin: 0 0 40px 0;
        }

        > * {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 3px solid $primary;
            background-color: $bg;
            transition: background-color 0.2s, box-shadow 0.2s, top 0.2s;

            &:hover {
                box-shadow: 0 0 6px $primary-light;
            }
            &:active {
                box-shadow: 0 0 15px $primary-light;
                top: 1px;
            }

            > i {
                text-align: center;
                color: $primary;
                font-size: 35px;
                transition: color 0.2s;
            }

            &:hover {
                background-color: $primary;
                box-shadow: 0 0 15px $primary;
                > i {
                    color: $bg;
                }
            }
        }
    }


    > p {
        color: $primary;
        font-size: 2.5em;
        text-align: center;
    }

    .form-page {
        // margin: 20px 50px 70px;
        padding: 50px 70px;
        border-radius: 20px;
        background: $bg;
        width: 100%;
        max-width: $large;
        color: $primary;
        transition: box-shadow 0.3s;
        box-shadow: 1px 1px 15px #494949;

        > div:not(.controllers) {
            display: flex;
            flex-direction: column;
            align-items: center;
            .title {
                font-size: 2.5em;
                font-weight: 600;
                text-align: center;
                margin-bottom: 40px;
            }
        }
        .input-box {
            margin: 20px 0;
            width: 100%;

            p.label {
                font-size: 1.5em;
                padding-left: 3px;
                margin-bottom: 10px;
                color: $primary-light;
            }

            input {
                height: 50px;
                color: $primary !important;
                width: 100%;
                background: $bg !important;
                border: 1px solid #474747;
                border-radius: 3px;
                font-size: 1.5em;
                padding: 5px 20px;
                transition: border 0.2s, box-shadow 0.2s;

                &:focus {
                    border: 1px solid #5f5f5f;
                    box-shadow: 1px 1px 15px #494949;
                }

                &::placeholder {
                    color: $primary-light;
                    font-size: 22px;
                }
            }
        }
        .checkbox-box {
            display: flex;
            align-items: center;
            margin: 20px 0;
            width: 100%;

            p.label {
                margin-left: 15px;
                font-size: 1.8em;
                color: $primary-light;
            }

            .custom-checkbox {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                height: 50px;
                width: 50px;
                color: $primary !important;
                background: $bg !important;
                border: 2px solid $primary;
                border-radius: 6px;
                font-size: 22px;
                padding: 5px 20px;
                transition: border 0.2s, box-shadow 0.2s;

                > i {
                    font-size: 30px;
                }

                &:hover {
                    box-shadow: 0 0 10px $primary;
                }
            }
        }
        .row {
            display: flex;
            justify-content: space-between;
            width: 100%;
            > * {
                width: calc(50% - 15px);
            }

            &.col-sep {
                > * {
                    width: auto;
                }
            }

            @media (max-width: $large) {
                flex-direction: column;

                > * {
                    width: 100%;
                }
            }
        }

        .col {
            display: flex;
            flex-direction: column;
        }

        .checkbox-page {
            display: flex;
        }
    }
}