@import "../../sass/variables.scss";

.dashboard {
    display: flex;
    flex-direction: column;
    font-size: 1em;
    transition: font-size 0.3s;
    @media (max-width: $medium) {
        font-size: 0.7em;
    }
    @media (max-height: $medium) {
        font-size: 0.7em;
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $primary;
        padding: 40px;
        border-radius: 0 0 50px 50px;

        > * {
            margin: 10px;
        }

        img {
            width: 100%;
            max-width: 200px;
            border-radius: 50%;
        }

        p {
            text-align: center;
            color: white;
            font-weight: 500;
            font-size: 2em;
        }

        p.adress {
            font-size: 1.5em;
            font-weight: 300;
            opacity: 0.8;
            margin-top: 0;
        }
    }
    .bottom {
        width: 100%;
        max-width: $large;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 15px 15px;

        .tickets {
            width: 100%;
            display: flex;
            margin-top: 50px;
            // justify-content: space-evenly;

            > div {
                flex: 1 1 0px;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: $bg;
                box-shadow: 1px 1px 15px rgb(54, 54, 54);
                border-radius: 10px;
                margin: 0 30px;
                padding: 10px;

                p {
                    text-align: center;
                    font-size: 1.7em;
                    color: white;
                    opacity: 0.8;
                    margin: 5px 0;

                    &.counter {
                        font-size: 2.4em;
                        color: $primary;
                        font-weight: 600;
                    }
                }

                @media (max-width: $medium) {
                    margin: 0 10px;
                }
            }
        }

        .add-ticket {
            cursor: pointer;
            position: relative;
            width: 100%;
            background: $primary;
            color: white;
            margin-top: 50px;
            padding: 25px 0;
            font-size: 2em;
            font-weight: 600;
            border-radius: 50px;
            opacity: 0.8;
            top: 0px;
            transition: box-shadow 0.2s, top 0.2s;

            &:hover {
                box-shadow: 0 0 6px $primary-light;
            }
            &:active {
                box-shadow: 0 0 15px $primary-light;
                top: 1px;
            }
        }
        @media (max-height: $medium) {
            .add-ticket, .tickets{
                margin-top: 20px;
            }
        }
    }
}