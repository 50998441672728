$small: 576px;
$medium: 768px;
$large: 992px;
$extra-large: 1200px;

$primary: #43D474;
$primary-light: #6ddd92;
$secondary: #132922;
$bg: #282828;
$bg-light: #2e2e2e;

$navbar-height: 70px;
