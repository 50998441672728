@import "../../sass/variables.scss";

.navbar {
    height: $navbar-height;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: $bg;
    border-bottom: 3px solid $bg-light;

    @media (max-width: $medium) {
        font-size: 0.75em;
    }

    a, .app-name {
        transition: text-shadow 0.3s, font-size 0.2s;

        &:hover {
            text-shadow: 3px 3px 3px $primary-light;
        }
    }

    a {
        text-decoration: none;
        color: $primary-light;
        font-size: 1.5em;
        margin: 0 10px;
    }

    .app-name {
        color: $primary;
        font-size: 2em;
    }

    div {
        display: flex;
        align-items: center;
    }
}