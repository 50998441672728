@import "../../sass/variables.scss";

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;

    p {
        transition: font-size 0.2s;
    }

    @media (max-width: $medium) {
        font-size: 0.7em;
    }

    > * {
        margin: 15px 0;
    }
    
    .title {
        font-size: 2.5em;
        margin-bottom: 60px;
        color: $primary;
    }

    img {
        height: 200px;
        width: 200px;
        border-radius: 50%;
    }
    .info {
        display: flex;
        flex-wrap: wrap;
        max-width: $extra-large;

        .info-box {
            flex: 50%;

            div {
                margin: 15px;
                color: $primary;
                background: $bg;
                padding: 20px;
                border-radius: 10px;
                transition: box-shadow 0.2s;

                .label {
                    font-size: 1.8em;
                    margin-bottom: 15px;
                }
                .value {
                    font-size: 1.6em;
                    color: $primary-light;
                }

                &:hover {
                    box-shadow: 0 0 6px $primary-light;
                }
            }
        }
    }
}